require('plyr/dist/plyr.css');

require('@fancyapps/fancybox/dist/jquery.fancybox.css');
require('@fancyapps/fancybox');


import 'lazysizes';
import 'flexslider';
import Plyr from 'plyr';

window.$ = $;
window.jQuery = $;


$(function() {

  $('.fancybox').fancybox({
    helpers : {
      title : {
        type : 'inside',
        position: 'top'
      }
    }
  });

  $('#menu-trigger').bind('click', function () {
    if ($(this).hasClass('open')) {
      $(this).toggleClass('open');
      $('#top-menu-wrap').slideUp();
    } else {
      $(this).toggleClass('open');
      $('#top-menu-wrap').slideDown();
    }

  });

  // the codes below causes issue on columns ground inside of home promo accordion FCE
  /*
  $('.column-group').each(function(){
    var groupHeight = $(this).height();
    $(this).find('.column').height(groupHeight + 'px');
  });
  */

  //home promotion accordion
  $('.promo-toggle-btn').click(function(){
    $(this).toggleClass("promo-toggle-btn-less");

    $('.promo-accordion-wrap').slideToggle();
    if($(this).hasClass('promo-toggle-btn-less')) {
      $(this).html('<span>View Less</span>');
    } else {
      $(this).html('<span>Load More</span>');
    }
  });

  //find physician
  var triggerX = $('#find-physician h3');
  var firstTriggerX = $('#find-physician h3:first');

  triggerX.next().hide();
  firstTriggerX.next().show();

  triggerX.click(function(){
    if($(this).hasClass('active')){
      // no action
    } else {
      // hide all content
      triggerX.next().slideUp('fast');
      // only show accordingly content
      $(this).next().slideDown('fast');

      // remove other active class
      triggerX.removeClass('active');
      // only add active class to accordingly h3
      $(this).addClass('active');
    }
  });

  // equal height columns
  $('#generated-content-1').css( 'min-height', $('#content-outer-wrap').height());

  if ($('#f3b').length) {
    $('#content-block-1, #content-block-2, #content-block-3').css( 'min-height', $('#content-wrap').height());
  }

  // find a physician show/hide search tabs on click
  $("ul.tabs li").click(function() {
    if($(this).hasClass("active")) {
      //do nothing
    } else {
      var index = $("ul.tabs li").index(this);

      $("ul.tabs li.active").removeClass("active");
      $(this).addClass("active");
      $(showCorrectForm(index));
    }
  });

  function showCorrectForm(index) {
    $("div.search-wrap form").hide();
    $("div.search-wrap form:eq(" + index + ")").show();
  }

  // find a physician show correct form on load
  var index = $("ul.tabs li.active").index();
  $(showCorrectForm(index));

  /*
   * Locations Page Functionality
   *
   */

  // initialize map and formatting
  $(moveLastCategory());

  // move last location category to right column
  function moveLastCategory() {
    var lastLocation = $(".column-wrap .column:first .location-category:last");
    $(".column-wrap .column:last").append(lastLocation);
    $(".column-wrap .column:last .location-category").css("display","block");
  }

  // when a filter is clicked show filtered results as list and on the map
  $(".filter-bar li").click(function() {
    if($(this).hasClass("active")) {
      //do nothing
    } else {
      var category = $(this).attr("class");
      var currentlyActive = $(".filter-bar li.active");

      $(".filter-bar li").removeClass("active");
      $(this).addClass("active");

      if(currentlyActive.is(':last-child')) {
        moveLastCategory();
      }

      filterLocationResults(category);
      changeMapImage(category);
    }
  });

  function filterLocationResults(category) {
    if(category == "all") {
      $(".column .location-category .photo").css("display", "none");
      $(".column .location-category").show();
    } else {
      $(".column .location-category .photo").css("display", "block");
      $(".column .location-category").hide();
      var categoryLocation = $(".column .location-category." + category);

      if (categoryLocation.parent().is(':last-child')) {
        $(".column-wrap .column:first").append(categoryLocation);
        categoryLocation.show();
      } else {
        categoryLocation.show();
      }
    }
  }

  function changeMapImage(category) {
    $(".column .map img").hide();
    $(".column .map img." + category).show();
  }

  // add an image link for MLK tab content at location page, by Ben



  $('.filter-bar a').click(function() {
    if($('.filter-bar li').hasClass('mlk')){
      $('#mlkhealthcenter-link').remove();
      $('<div id="mlkhealthcenter-link"><a href="http://mlkhealthcenter.com/" target="_blank" style="margin-top: 65px; display: block;"><img src="http://www.bronxcare.org/fileadmin/SiteFiles/Images/6-BronxCare_Network/Locations/mlkhealthcenter-link.jpg"></a></div>').insertAfter( $('.content-wrap .column-wrap .mlk ul') );
    } else {

    }
  });


  // make the location column & profile column have the same height on physician single view page
  var locationColumnHeight = $('.profile .location').outerHeight();
  var profileColumnHeight = $('.profile .header').outerHeight() + $('.profile .details').outerHeight();
  var newLocationColumnHeight = profileColumnHeight + 20;
  if(profileColumnHeight > locationColumnHeight){
    $('.profile .location').height(newLocationColumnHeight);
  } else {

  }


  // YouTube and Vimeo Lighbox videos
  $('.youtube-vimeo-lightbox').bind('click', function() {

    if ($(window).width() > 730) {
      var videoWidth = 640;
    } else {
      var videoWidth = 460;
    }

    var href = $(this).attr('href');
    var title = $(this).attr('title');

    if ($(this).hasClass('aspect-ratio-43')) {
      var videoHeight = Math.round(videoWidth*0.75);
    } else {
      var videoHeight = Math.round(videoWidth*0.5625);
    }

    $.fancybox({
      'padding'		: 10,
      'content'		: '<iframe width="' + videoWidth + '" height="' + videoHeight + '" src="' + href + '&autoplay=1" frameborder="0" webkitAllowFullScreen allowfullscreen></iframe>',
      'transitionIn'	: 'elastic',
      'transitionOut'	: 'elastic',
      'title'			: title
    });

    return false;
  });

  /* Mobile nav from menu.js*/
  $('#mobile-nav-trigger').on('click tap', function () {
    if ($(this).hasClass('open')) {
      $(this).toggleClass('open');
      $('#mobile-nav').removeClass("active");
      $('#mobile-nav > ul li:not(.no-submenu)').removeClass("active");
    } else {
      $(this).toggleClass('open');
      $('#mobile-nav').toggleClass("active");
    }
  });

  /* Mobile nav submenu */
  $('#mobile-nav > ul > li:not(.no-submenu)').each(function(){
    var item = $(this);
    $(item).on('click tap', '> span strong', function(e) {
      e.preventDefault();

      if ($(item).hasClass('active')) {
        $(item).removeClass('active');
      } else {
        $(item).toggleClass('active');
      }
    });
  })

  /* Mobile nav submenu > submenu */
  $('#mobile-nav > ul > li:not(.no-submenu) li.has-submenu').each(function(){
    var item = $(this);
    $(item).on('click tap', '> span strong', function(e) {
      e.preventDefault();

      if ($(item).hasClass('active')) {
        $(item).removeClass('active');
      } else {
        $(item).toggleClass('active');
      }
    });
  })


  $("[data-inline-video]").on('click',function(e){
      e.preventDefault();
      $(this).hide();
      $(this).attr('style','display:none !important');
      let targetBase = $(this).data('src');
      $(targetBase).show();
      $(targetBase).attr('style', 'display:block !important');
      let target = targetBase + 'video'
      $(target).show();
      $(target).attr('style', 'display:block !important');
      let autoplay = $(this).data('autoplay');
      let player = new Plyr(target, {controls: ['play', 'progress', 'fullscreen'], autoplay: autoplay});
      if (autoplay) {
          player.play()
      }
  });


  $("[data-video-lightbox]").fancybox({
      toolbar  : false,
      smallBtn : true,
      beforeShow: function () {

      },
      afterShow: function () {
          let target = this.src;
          target += 'video'
          let $this =  $('[data-src="'+this.src+'"]');
          let autoplay = $this.data('autoplay');
          let player = new Plyr(target, {controls: ['play', 'progress', 'fullscreen'], autoplay: autoplay});
          console.log(autoplay,"HH")

          $this.data('player', player);
          if (autoplay == false) {
            console.log("Here")
              player.stop()
          }
      },
      afterClose: function () {
          let player = $('[data-src="'+this.src+'"]').data('player');
          if (player) {
              player.destroy();
          }
      }
  });

// END DOC READY
});
